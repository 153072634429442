// Generated by Framer (d018716)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, transformTemplate, addFonts, withCSS, addPropertyControls, ControlType, cx, useAddVariantProps, useVariantState, CycleVariantState, RichText, Container, getFonts, useHydratedBreakpointVariants, removeHiddenBreakpointLayers, SSRVariants, GeneratedComponentContext } from "framer";
import { useRandomID } from "https://framer.com/m/framer/randomID.js@^2.0.0";
import Navigation from "../canvasComponent/GEoi1Pm9r";
const NavigationFonts = getFonts(Navigation);

const cycleOrder = ["ja7VHPYPJ"];

const breakpoints = {}

const isBrowser = typeof document !== "undefined";

const variantClassNames = {"ja7VHPYPJ": "framer-v-1uesjik"};

if (isBrowser) {
                    removeHiddenBreakpointLayers("ja7VHPYPJ", breakpoints, variantClassNames);
            }

const humanReadableVariantMap = {};

const transitions = {"default": {"duration": 0}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "ja7VHPYPJ", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const [baseVariant, hydratedBaseVariant] = useHydratedBreakpointVariants(variant, breakpoints, false);
const gestureVariant = undefined;
const transition = transitions.default;

const variantProps = React.useMemo(() => ({}), []);

const addVariantProps = useAddVariantProps(hydratedBaseVariant, gestureVariant, variantProps);

const defaultLayoutId = useRandomID();

const { pointerEvents, ...style } = externalStyle

return (<GeneratedComponentContext.Provider value={{ primaryVariantId: "ja7VHPYPJ", variantProps: variantProps, variantClassNames: variantClassNames}}>
<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated className={cx("framer-mMSnW")} style={{"display": "contents", "pointerEvents": pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-1uesjik", className)} style={{...style}} name="Desktop" ref={ref} {...addVariantProps("ja7VHPYPJ")}><RichText fonts={["Inter-ExtraBold"]} withExternalLayout={true} verticalAlignment="top" center="x" __fromCanvasComponent={true} className="framer-v1z8vv" transformTemplate={(_, t) => `translateX(-50%) ${t}`} __htmlStructure={"<p><span style=\"--framer-font-family:&quot;Inter-ExtraBold&quot;, &quot;Inter&quot;, sans-serif; --framer-font-style:normal; --framer-font-weight:800; --font-selector:SW50ZXItRXh0cmFCb2xk; --framer-text-color:rgb(51, 51, 51); --framer-font-size:100px; --framer-letter-spacing:-3px;\">{{ text-placeholder }}</span></p>"} htmlFromDesign={"<p><span style=\"--framer-font-family:&quot;Inter-ExtraBold&quot;, &quot;Inter&quot;, sans-serif; --framer-font-style:normal; --framer-font-weight:800; --font-selector:SW50ZXItRXh0cmFCb2xk; --framer-text-color:rgb(51, 51, 51); --framer-font-size:100px; --framer-letter-spacing:-3px;\">404</span></p>"} {...addVariantProps("IIIdcmSHm")}/><Container className="framer-16sdh6w-container" transformTemplate={(_, t) => `translateX(-50%) ${t}`} {...addVariantProps("o_J0ChUt9-container")}><Navigation width="100%" height="100%" layoutId="o_J0ChUt9" id="o_J0ChUt9" variant="fFdGd6igW" style={{"width": "100%", "height": "100%"}} {...addVariantProps("o_J0ChUt9")}/></Container></motion.div>
</motion.div>
</LayoutGroup>
</GeneratedComponentContext.Provider>)

});

const css = [".framer-mMSnW [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mMSnW .framer-1uesjik { position: relative; width: 1200px; height: 1000px; background-color: #ffffff; }", ".framer-mMSnW .framer-v1z8vv { position: absolute; width: auto; height: auto; left: 50%; top: 432px; flex: none; transform: translateX(-50%); white-space: pre; --framer-paragraph-spacing: 0px; --framer-link-text-color: #0099ff; --framer-link-text-decoration: underline; }", ".framer-mMSnW .framer-16sdh6w-container { position: absolute; width: 100%; height: 80px; left: 50%; top: 0px; flex: none; transform: translateX(-50%); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1000
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName": "variant", "data": {"default": {"layout": ["fixed", "fixed"]}}}
 * @framerResponsiveScreen
 */
const FramerKBlFSas6i: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerKBlFSas6i;

FramerKBlFSas6i.displayName = "404";

FramerKBlFSas6i.defaultProps = {"width": 1200, "height": 1000};

addFonts(FramerKBlFSas6i, [...NavigationFonts]);